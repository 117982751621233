import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { SortChip, SearchInput } from './components';
import {
  AccountSort,
  sortByCreationDateDesc,
  sortByOrdersActiveDesc,
  sortByOrdersDesc,
} from '@/webapi/use-accounts-api';
import { isBrowser } from '@/utils/browser';
import { MyAccountsContext } from '@/features/accounts/context';

export function AccountsFilter() {
  const { sort, searchText, setSort, setSearchText } =
    useContext(MyAccountsContext);
  const onSelection = (value: AccountSort) => {
    setSort(value);
  };

  const onSearchTextChanged = (ev) => {
    const text = ev.target.value.replace(/ /g, `_`) as string;
    setSearchText(text);
  };

  const debouncedSearch = useDebouncedCallback(onSearchTextChanged, 300);

  if (!isBrowser()) {
    return null;
  }

  return (
    <Wrapper>
      <SortChip
        onClick={() => {
          onSelection(sortByCreationDateDesc);
        }}
        vselected={sort !== sortByOrdersDesc && sort !== sortByOrdersActiveDesc}
      >
        New
      </SortChip>
      <SortChip
        onClick={() => {
          onSelection(sortByOrdersDesc);
        }}
        vselected={sort === sortByOrdersDesc}
      >
        Top
      </SortChip>
      <SortChip
        onClick={() => {
          onSelection(sortByOrdersActiveDesc);
        }}
        vselected={sort === sortByOrdersActiveDesc}
      >
        Top Active
      </SortChip>
      <SearchInput defaultValue={searchText} grow onChange={debouncedSearch} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  user-select: none;
  gap: 1.5rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
`;
