import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import { AccountSort, sortByCreationDateDesc } from '@/webapi/use-accounts-api';
import { LS_SEARCH_STORES_KEY, LS_SORT_STORES_KEY } from '@/utils/consts';

export const MyAccountsContext = React.createContext({} as MyAccountsCtx);

export function newMyAccountsContext(): MyAccountsCtx {
  const [page, setPage] = useState(0);
  const limit = 10;
  const [searchText, setSearchText] = useSessionStorage(
    LS_SEARCH_STORES_KEY,
    ``,
  );
  const [sort, setSort] = useSessionStorage(
    LS_SORT_STORES_KEY,
    sortByCreationDateDesc,
  );
  useEffect(() => {
    setPage(0);
  }, [sort, searchText]);
  return { sort, searchText, setSort, setSearchText, page, limit, setPage };
}

export interface MyAccountsCtx {
  page: number;
  limit: number;
  sort: AccountSort;
  searchText: string;
  setSort: (sort: AccountSort) => void;
  setSearchText: (text: string) => void;
  setPage: (page: number) => void;
}
