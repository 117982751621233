import React, { useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Flexbox } from '@/components/flex';

export function SearchInput({
  defaultValue,
  value,
  onChange,
  grow,
  inputStyle,
}: {
  defaultValue: string;
  value?: string;
  onChange: (ev) => void;
  grow?: boolean;
  inputStyle?: any;
}) {
  const [isBig, setIsBig] = useState(false);
  const iStyles = inputStyle || {};
  const onFocus = () => {
    grow && setIsBig(true);
  };

  const onBlur = () => {
    grow && setIsBig(false);
  };

  return (
    <SearchWrapper>
      <TextInput
        defaultValue={defaultValue}
        value={value}
        style={{ ...iStyles }}
        onChange={onChange}
        isBig={isBig}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="Search"
      />
      <StaticImage
        src="../../../assets/search.svg"
        alt="edit"
        placeholder="none"
        loading="eager"
        height={15}
      />
    </SearchWrapper>
  );
}

const SearchWrapper = styled(Flexbox)`
  && {
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .gatsby-image-wrapper {
      position: absolute;
      right: 1.5rem;
    }
  }
`;

const TextInput = styled.input`
  && {
    height: 100%;
    width: ${(props: { isBig: boolean }) => (props.isBig ? `20rem` : `10rem`)};
    transition: width 0.3s ease-in-out;
    appearance: none;
    -webkit-appearance: none;
    pointer-events: auto;
    outline: none;
    background: none;

    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'JetBrains Mono', serif;

    color: #8f9daa;

    border: none;

    box-shadow: none;

    border-radius: 2rem;

    padding: 0 3.2rem 0 1.5rem;

    &&::placeholder {
      color: #8f9daa;
      opacity: 1;
    }

    &&:-ms-input-placeholder {
      color: #8f9daa;
      opacity: 1;
    }

    &&::-ms-input-placeholder {
      color: #8f9daa;
      opacity: 1;
    }

    &&::-webkit-outer-spin-button,
    &&::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &&[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

interface P {
  vdisabled: boolean;
  vselected: boolean;
}

export const SortChip = styled.span`
  background: ${(props: P) =>
    props?.vselected ? `rgba(199, 207, 218, 0.45)` : `transparent`};

  font-size: 1.2rem;
  font-weight: ${(props: P) => (props?.vselected ? `bolder` : `bold`)};
  font-family: 'JetBrains Mono', serif;

  color: ${(props: P) => (props?.vselected ? `#000000` : `#8F9DAA`)};

  pointer-events: ${({ vdisabled }: P) => (vdisabled ? `none` : `auto`)};
  cursor: ${({ vdisabled }: P) => (vdisabled ? `default` : `pointer`)};
  opacity: ${({ vdisabled }: P) => (vdisabled ? 0.5 : 1)};

  border-radius: 2rem;
  padding: 0.6rem 1.5rem;
`;
