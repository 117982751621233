export interface AccountSort {
  option: AccountSortOption;
  direction: AccountSortDirection;
}

export enum AccountSortOption {
  __NA = `__NA`,
  BY_CREATION_DATE = `BY_CREATION_DATE`,
  BY_ORDERS = `BY_ORDERS`,
  BY_ORDERS_ACTIVE = `BY_ORDERS_ACTIVE`,
}

export enum AccountSortDirection {
  ___NA = 1,
  ASC = 1,
  DESC = -1,
}

export const sortByCreationDateAsc: AccountSort = {
  option: AccountSortOption.BY_CREATION_DATE,
  direction: AccountSortDirection.ASC,
};

export const sortByCreationDateDesc: AccountSort = {
  option: AccountSortOption.BY_CREATION_DATE,
  direction: AccountSortDirection.DESC,
};

export const sortByOrdersAsc: AccountSort = {
  option: AccountSortOption.BY_ORDERS,
  direction: AccountSortDirection.ASC,
};

export const sortByOrdersDesc: AccountSort = {
  option: AccountSortOption.BY_ORDERS,
  direction: AccountSortDirection.DESC,
};

export const sortByOrdersActiveDesc: AccountSort = {
  option: AccountSortOption.BY_ORDERS_ACTIVE,
  direction: AccountSortDirection.DESC,
};

export const sortByOrdersActiveAsc: AccountSort = {
  option: AccountSortOption.BY_ORDERS_ACTIVE,
  direction: AccountSortDirection.ASC,
};
