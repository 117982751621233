import { CachePolicies, useFetch } from 'use-http';
import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { routes } from '@/webapi/routes';
import { Pages } from '@/webapi/pages';

export function useAccounts(req: any) {
  const { post, error } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [accounts, setAccounts] = useState<Array<any>>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const resp = (await post(routes.listAccounts(), req)) as any;
      // const resp = (await get(routes.myAccounts())) as any;
      if (resp?.accounts) {
        setAccounts(resp.accounts);
        setTotal(resp.total);
        setLoading(false);
      } else {
        setAccounts([]);
        setLoading(false);
        setTotal(0);
      }
    })();
  }, [req.searchText, req.page, req.limit, req.sortDirection, req.sortOption]);

  async function onSelect(storeAlias: string) {
    try {
      setLoading(true);
      await post(routes.selectAccount(), {
        storeAlias,
      });
      if (!error) {
        await navigate(`${Pages.DASHBOARD}?al=${storeAlias}`);
      }
    } catch (e) {
      console.error(`failed selecting a store`, e);
    }
  }

  return {
    accounts,
    loading,
    total,
    onSelect,
    error,
  };
}
